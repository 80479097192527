"use client";

import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

export type UseSafePushReturnType = {
  safePush: (path: string) => void;
};

export const useSafePush = (): UseSafePushReturnType => {
  const [onChanging, setOnChanging] = useState(false);

  const handleRouteChange = useCallback(() => {
    setOnChanging(false);
  }, []);

  const router = useRouter();
  // This is used to avoid route pushing errors when users click multiple times
  // or when the network is slow:  "Error: Abort fetching component for route"
  const safePush = (path: string) => {
    if (onChanging) {
      return;
    }
    setOnChanging(true);
    router.push(path);
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, handleRouteChange]);

  return {
    safePush,
  };
};

export default useSafePush;
